<template>
	<div class="apr">
			<div class="cl_maxres">
        <div class="cl_ribect">
          退款详情
        </div>
        <van-steps direction="vertical" :active="len">
            <van-step v-for="(item,k) in data" :key="k">
              <h3>{{ item.title }}</h3>
              <h4>{{ item.text }}</h4>
              <p>{{ item.time }}</p>
            </van-step>
          </van-steps>
      </div>
	</div>
</template>

<script>
    import { ref } from 'vue';
	export default {
		name: 'progComponent',
		props: {
        data: {
            required: true,
            type: Array,
        }
        },
        data() {
            return {
                len: 0,
            }
        },
        setup() {
            const active = ref(1);
            return { active };
        },
        mounted() {
            this.len = this.data.length - 1;
        }

  };
</script>

<style scoped>
	.apr {
		width: 98%;
		margin: 6rem auto 0;
        /*border: 1px solid red;*/
        border-radius: 80px;
        font-size: 1.5rem;
        }
  /*退款详情*/
  .cl_maxres {
    width: 95%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: #f4f3f3;
    height: auto;
    padding: 2rem 0 0;
    box-sizing: border-box;
  }
  .cl_ribect {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
    text-indent: 2em;
	}

  ::v-deep .van-steps {
    background-color: #f4f3f3;
    border-radius: 50px;
  }

</style>

