<template>
	<div class="ap">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
		<van-pull-refresh
			v-model="loading"
			success-text="刷新成功"
			@refresh="onRefresh"
			>
			<search @search="search"  />
			<user :data="data" @change="getremove"/>
		</van-pull-refresh>
	</div>
</template>

<script>
	import { list } from '@/api/home';
	import { remove } from '@/api/home';
	import { Toast } from 'vant';
	// import titleComponent from '../title.vue'
	import searchComponent from './components/search.vue'
	import userComponent from './components/user.vue'
	
	export default {
		name: 'managementComponent',
    components: {
    //   titleComponent,
      search: searchComponent,
      user: userComponent,
    },
		// setup() {
		// 	// 返回上一级
		// 	const onClickLeft = () => history.back();
		// 	return {
		// 	onClickLeft,
		// 	};
		// },

		data() {
			return {
				title: '用户管理',
				loading: false,
				data: [],
			}
		},
		created() {
			this.getlist();
		},
		update() {
			this.search();
		},
		methods: {
			// 返回上一级
			onClickLeft() {
				this.$router.push({path: '/home'});
			},
			//获取用户列表
			getlist() {
				list().then(res => {
					if (res.data.code == 200) {
						this.data = res.data.data;
					} else {
						Toast.fail('网络错误');
						this.$router.push({path: '/home'})
					}
				})
			},
			// 删除用户
			getremove(value) {
				remove(value).then(res => {
					if (res.data.code == 200) {
						this.getlist();
						Toast.success('删除成功');
					} else {
						Toast.fail('删除失败');
					}
				})
			},
			search(val) {
				list(val).then(res => {
					if (res.data.code == 200) {
						this.data = res.data.data;
					} else {
						Toast.fail('搜索错误');
						this.$router.push({path: '/home'})
					}
				})
			},
			onRefresh() {
				setTimeout(() => {
					this.getlist();
					this.loading = false;
				}, 1000);
			}
		}
	}
</script>

<style scoped>
	.ap {
		width: 100%;
		/* min-height: none; */
		/* border: 1px solid rgb(0, 255, 55); */

	}
	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}
	::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
</style>