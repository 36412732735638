<template>
	<div class="ap">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />

			<video id="id_video" controls autoplay>
				<source :type="'video/mp4' || 'video/avi' || 'video/mov'"/>
			</video>
	</div>
</template>

<script>
	import { getrecord } from '@/api/video';
import { Dialog, Toast } from 'vant';
	export default {
		name: 'broadcastComponent',
		data() {
			return {
				id: -1,
				title: '探视回放',
				// video: '',
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.getlist();
		},
		methods: {
			getlist() {
				getrecord(this.id).then(res => {
					if (res.data.code == 200) {
						// this.video = res.data.data.videoUrl;
						document.getElementById("id_video").src = res.data.data.videoUrl;
					} else if(res.data.code == 400) {
						Toast.fail('暂未录制视频')
						history.back();
					} else {
						Toast.fail('网络错误')
						history.back();
					}
			})
			},
			// 结束观看
			onClickLeft() {
				Dialog.confirm({
						// title: '标题',
					message: '是否结束观看探视回放！',
				})
				.then(() => {
					history.back();
				})
				.catch(() => {
					console.log(123);
				});
			}
		}
		
	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}

	.cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}
	::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
	#id_video {
		width: 100%;
		height: auto;
    margin-top: 4.8rem;
	}
</style>
