<template>
  <div class="app">
    <div class="cl_max_touder">
      <div class="cl_rate">
        <div class="cl_rate_title">服务满意度：</div>
        <van-rate
            v-model="data.evaluateStar"
            :size="25"
            color="#FFBB38"
            void-color="whitesmoke"
            void-icon="star"
        />
      </div>
      <div class="cl_cell">
        <p>文字评价：</p>
        <van-cell-group inset>
          <van-field
              v-model="data.evaluateInfo"
              autosize
              maxlength="100"
              placeholder="请输入评价"
              rows="5"
              :rules="[{ required: true, message: '请输入评价' }]"
              show-word-limit
              type="textarea"
          />
        </van-cell-group>
      </div>
    </div>

    <van-button round type="primary" @click="getplayback()">匿名提交</van-button>
  </div>
</template>

<script>
// import {ref} from 'vue';
import { evaluate } from "@/api/home";
import { Toast } from "vant";

// import {reactive} from "vue";
export default {
  name: 'evalComponent',
  props: ['id','visitTime'],
  // setup() {
  //   let data = reactive({
  //     rate: '1',
  //     group: '',
  //   })
  //   return {data};
  // },
  data() {
    return {
      data: {
        appointId: this.id,
        elavtime: this.visitTime,
        evaluateStar: '1',
        evaluateInfo: '',
      }
    }
  },
  // watch: {
  //   id() {
  //     this.data.appointId = this.id;
  //   }
  // },
  methods: {
    getplayback() {
      console.log(this.data)
      evaluate(this.data).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          Toast.success('提交成功');
          this.$router.push({path: '/ended',query: {
            id: this.data.appointId,
            elavtime: this.data.elavtime,
          }});
        } else {
          Toast.fail('不能重复评价');
        }
      })
    }
  }
}
</script>

<style scoped>
  .app {
    width: 95%;
    margin: 6.5rem auto 0;
    background-color: white;
    border-radius: 50px;
    position: relative;
  }
  .cl_max_touder {
    width: 100%;
    /*border: 1px solid red;*/

  }
  .cl_rate {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #EEEEEE;
    padding: 0.8rem 0;
  }
  .cl_rate_title {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 1rem;
  }
  .van-rate {
    margin-right: 1rem;
  }
  /*留言*/
  .cl_cell {
    margin: 0 auto;
    padding-bottom: 2rem;
  }
  .cl_cell p {
    margin-left: 2rem;
    font-size: 1.56em;
    font-weight: 400;
    color: #212121;
  }

  .van-field {
    border: 1px solid #E0E0E0;
    border-radius: 30px;
  }
  /*提交*/
  ::v-deep .van-button {
    width: 90%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    background-color: #3D53F5;
    position: absolute;
    border-radius: 20px;
    left: 50%;
    transform: translate(-50%);
    top: 50rem;
  }
</style>

