import request from '@/utils/request'

// 轮播图
export function getchart() {
  return request({
    url: 'client/user/chart',
    method: 'post',
  })
}

// 用户头像
export function getavatar() {
  return request({
    url: 'client/user/avatar',
    method: 'post',
  })
}

// 获取首页
export function list() {
    return request({
      url: 'client/appoint/list',
      method: 'post',
    })
  }

  // 签到/待探视/申请失败
  export function getlist(id) {
    return request({
      url: 'client/appoint/list/' + id,
      method: 'post',
    })
  }

  // 签到成功
  export function check(id) {
    return request({
      url: 'client/appoint/check/' + id,
      method: 'post',
    })
  }

    // 取消预约提交信息
    export function getcancel(data) {
      return request({
        url: 'client/appoint/cancel',
        method: 'post',
        data
      })
    }
  