<template>
	<div class="ap">
		<div class="logo_img"><img src="../../../../public/logo.png" alt=""></div>
		<div class="logo_name">{{title}}</div>
	</div>
</template>

<script>
	export default {
		name: 'logoComponent',
		props: {
			title: {
				required: true,
				type: String,
			}
		}
	}
</script>

<style scoped>
	.ap {
		width: 50%;
		margin: 0 auto;
		text-align: center;
		margin-top: 10rem;
	}
	.logo_img img {
		width: 10rem;
	}
	.logo_name {
		font-size: 3rem;
		text-align: center;
		margin-top: 2rem;
	}
</style>
