import request from '@/utils/request'

  // 视频回放
export function getrecord(id) {
    return request({
      url: 'client/video/record/' + id,
      method: 'post',
    })
  }
  // 视频进入
export function getvideo(id) {
    return request({
      url: 'client/video/' + id,
      method: 'get',
    })
  }

  // 结束探视
export function appoint(id) {
    return request({
        url: '/client/appoint/end/' + id,
        method: 'post',
    })
}
