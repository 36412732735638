<template>
	<div class="ap">
		<logoComponent :title="title"></logoComponent>

		<div class="cl_form">

			<van-form @submit="onSubmit">
				<van-cell-group inset>
					<van-field v-model="password" name="password" :type="types1" colon label="新密码" placeholder="请输入密码"
						:rules="[{ required: true, message: '密码不能为空' }]">
						<template #button>
							<van-icon v-if="!flag1" name="closed-eye" @click="getpwd" />
							<van-icon v-else-if="flag1" name="eye" @click="getpwd" />
						</template>
					</van-field>
					<van-field v-model="new_password" name="new_password" :type="types2" colon label="确认密码"
						placeholder="请输入确认密码" :rules="[{ required: true, message: '密码不能为空' },{ validator, message: '两次密码输入不一致'}]">
						<template #button>
							<van-icon v-if="!flag2" name="closed-eye" @click="getnewpwd" />
							<van-icon v-else-if="flag2" name="eye" @click="getnewpwd" />
						</template>
					</van-field>
				</van-cell-group>
				<van-button round block type="primary" native-type="submit" class="cl_van_button">提交</van-button>
			</van-form>
		</div>
		<!-- <van-dialog /> -->
	</div>
</template>

<script>
	import logoComponent from '../login/components/logo.vue'
	import {
		ref
	} from 'vue';
	// import {
	// 	reactive
	// } from 'vue';
	import {
		useRouter,useRoute
	} from 'vue-router';
	import { Dialog, Toast } from 'vant';
	// import { Toast } from 'vant';
	import { modify } from '@/api/login'
	import { setToken } from '@/utils/auth'

	export default {
		name: 'lsetpwdComponent',
		components: {
			logoComponent,
		},
		setup() {
			const title = ref('设置密码');
			let password = ref('');
			let new_password = ref('');

			const router = useRouter();
			const route = useRoute();
			let uid = ref(route.query.id);
			const onSubmit = (values) => {
				if (values.password == values.new_password) {
					const data = {
					userId: uid.value,
					password: values.password,
					check: values.new_password,
				}
				modify(data).then(res => {
					const data = res.data;
					if (data.code == 200) {
						setToken(data.data.token);
						// Toast.loading({
						// 	message: '加载中...',
						// 	forbidClick: true,
						// });
						Toast.success('登录成功');
						router.push({path: '/about'});
					} else {
						Dialog.alert({
							message: data.message,
						})
					}
				})
				} else {
					Dialog.alert({
					message: '两次密码输入不一致',
					});
				}
	
			};
			let flag1 = ref(false);
			let types1 = ref('password');
			function getpwd() {
				if (flag1.value) {
					types1.value = 'password';
					flag1.value = false;
				} else {
					types1.value = 'text';
					flag1.value = true;
				}
			}
			let flag2 = ref(false);
			let types2 = ref('password');
			function getnewpwd() {
				if(flag2.value) {
					types2.value = 'password';
					flag2.value = false;
				} else {
					types2.value = 'text';
					flag2.value = true;
				}
			}
			const validator = () => {
				if (password.value == new_password.value) {
					return true;
				} else {
					return false;
				}
			}
			return {
				title,
				password,
				new_password,
				onSubmit,
				flag1,
				getpwd,
				types1,
				flag2,
				getnewpwd,
				types2,
				validator,
			};
		},

	};
</script>

<style scoped>
	.ap {
		/* background-image: url('../../../public/image/c63e013cea559549c78a30ddf.jpg');
		background-repeat: no-repeat; */
		position: relative;
	}

	.cl_form {
		width: 100%;
		margin: 8rem auto 0;
	}

	::v-deep .cl_van_button {
		width: 70%;
		position: absolute;
		bottom: -15rem;
		left: 50%;
		transform: translate(-50%);
	}
</style>
