// import { Vue, createApp } from 'vue'
// import App from './App.vue'
// import router from './router/router.js'

// Vue.config.productionTip = false

// createApp(App).mount('#app')
// new Vue({
//   render: h => h(App),
//   router,
// }).$mount('#app')

import amfeFlexible from './postcss.config.js'
import { createApp } from 'vue'
import App from './App.vue'
import Router from './router/router.js'
import { Button } from 'vant';
import { Form, Field } from 'vant';
import { Dialog } from 'vant';
import { Icon } from 'vant';
import { Toast } from 'vant';
import { NavBar } from 'vant';
import { CountDown } from 'vant';
import { NoticeBar } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Tab, Tabs } from 'vant';
import { SwipeCell } from 'vant';
import { Search } from 'vant';
import { Rate } from 'vant';
import { Image as VanImage } from 'vant';
import { Step, Steps } from 'vant';
import { PullRefresh } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Divider } from 'vant';
import { Tag } from 'vant';
import { Loading } from 'vant';
// import Swiper from 'swiper'

// createApp(App).mount('#app')

const app = createApp(App)
// const app = createApp();
app.use(Router);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Cell);
app.use(Button);
app.use(amfeFlexible);
app.use(Dialog);
app.use(Icon);
app.use(Toast);
app.use(NavBar);
app.use(CountDown);
app.use(NoticeBar);
app.use(Popup);
app.use(Radio);
app.use(RadioGroup);
app.use(Tab);
app.use(Tabs);
app.use(SwipeCell);
app.use(Search);
app.use(Rate);
app.use(VanImage);
app.use(Step);
app.use(Steps);
app.use(PullRefresh);
app.use(Swipe);
app.use(SwipeItem);
app.use(Divider);
app.use(Tag);
app.use(Loading);
// app.use(Swiper)
// app.use(ElementPlus)
app.mount('#app')
