<template>
	<div class="app">
		<titleComponent :title="title"></titleComponent>

		<relsourComponent :val="id" :data="data" :unix="unix" :image="image" @finish="onfinish" @unixtime="unixtime"></relsourComponent>

    <van-button v-show="stats" round type="primary"  @click="getplayback(data.appointId)">进入探视</van-button>
    <van-button v-show="!stats" round type="primary" disabled>进入探视</van-button>
  </div>
</template>

<script>
	import titleComponent from '../title.vue'
	import relsourComponent from './components/visit.vue'
  import { Toast } from 'vant';
  import { getavatar, getlist } from '@/api/about'
import { layout } from '@/utils/layout';
  import {getrecord} from "@/api/video";
	export default {
		name: 'visitComponent',
		components: {
			titleComponent,
			relsourComponent,
		},
		data() {
			return {
				id: -1,
        unix: -1,
				title: '待探视',
        stats: false,
        image: '',
        data: {},
			}
		},
    created() {
			this.id = this.$route.query.id;
      layout();
      this.getlist();
    },
    methods: {
      getplayback(val) {
          getrecord(this.id).then(res => {
            if (res.data.code == 200) {
              this.$router.push({path: '/broadcast',query: {id: val}});
            } else if(res.data.code == 400) {
              Toast.fail('暂未录制视频')
            } else {
              Toast.fail('网络错误')
            }
          })

      },
      getlist() {
        getlist(this.id).then(res => {
          if (res.data.code == 200) {
            let data = res.data.data;
            let dateTime = data.appointVisitTime+ ' ' + data.appointStartTime;
            data.appointVisitTime = dateTime + ' - ' + data.appointEndTime;
            let new_date = Date.parse(new Date());  //当前时间的时间戳
            let old_date = Date.parse(dateTime);    //预约时间的时间戳
            // console.log(typeof(old_date));
            this.unix = old_date > new_date ? old_date - new_date : 0;
            this.data = data;
          } else {
            Toast.error('网络错误');
            this.$router.push({path: '/about'});
          }
        });
        getavatar().then(res => {
          if(res.data.code == 200) {
            this.image = res.data.data.avatarUrl;
          }
        })
      },
      // 倒计时结束进入探视
      // onfinish(val) {
      //   this.stats = val;
      // },
      unixtime(value) {
        this.stats = value;
        // console.log(value);
      }
    }
	}
</script>

<style scoped>
	.app {
    width: 100%;
    height: 100%;
    border: 1px solid #F9F9F9;
	}
  ::v-deep .van-button {
    width: 80%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    background-color: #18A957;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 30rem;
  }
</style>
